import React, { useState, useEffect } from "react";
import { db } from "../firebase.js";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { Stack, Box, Button, useTheme } from "@mui/material";
import { ExpandMore, ExpandLess, Close, CheckCircle, Cancel, ErrorOutline } from "@mui/icons-material";
import Logo from "../generic/Logo";

function Assign() {
  const authRef = collection(db, "user-emails");
  const [judges, setJudges] = useState([]);
  const [expandedJudge, setExpandedJudge] = useState(null);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [, setSelectedJudge] = useState(null);
  const [judgesForDropdown, setJudgesForDropdown] = useState([]);
  const theme = useTheme();
  const [statistics, setStatistics] = useState({ totalJudges: 0, completedJudges: 0, totalProgress: 0 });

  const fetchJudges = async () => {
    try {
      const snapshot = await getDocs(authRef);
      const judgeList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        assignedApplications: doc.data().assignedApplications || [],
      }));
      setJudges(judgeList);
      setJudgesForDropdown(judgeList.map((j) => j.email));
      calculateStatistics(judgeList);
    } catch (error) {
      console.error("Error fetching judges:", error);
    }
  };

  const calculateStatistics = (judgeList) => {
    const totalJudges = judgeList.length;
    const completedJudges = judgeList.filter((j) => j.assignedApplications.every((app) => app.judged)).length;
    const totalProgress =
      totalJudges > 0
        ? Math.round(
            (judgeList.reduce((sum, j) => sum + j.assignedApplications.filter((app) => app.judged).length, 0) /
              judgeList.reduce((sum, j) => sum + j.assignedApplications.length, 0)) *
              100
          )
        : 0;

    setStatistics({ totalJudges, completedJudges, totalProgress });
  };

  const moveApplication = async (application, fromJudge, toJudgeEmail) => {
    try {
      const toJudge = judges.find((j) => j.email === toJudgeEmail);
      const updatedFromJudgeApps = fromJudge.assignedApplications.filter((app) => app.id !== application.id);
      const updatedToJudgeApps = [...toJudge.assignedApplications, application];

      await updateDoc(doc(authRef, fromJudge.id), {
        assignedApplications: updatedFromJudgeApps,
        toJudge: fromJudge.toJudge - 1,
      });
      await updateDoc(doc(authRef, toJudge.id), {
        assignedApplications: updatedToJudgeApps,
        toJudge: toJudge.toJudge + 1,
      });

      fetchJudges();
      setSelectedApplication(null);
      setSelectedJudge(null);
      alert(`Application "${application.startup_name}" moved to ${toJudgeEmail}`);
    } catch (error) {
      console.error("Error moving application:", error);
      alert("Failed to move application.");
    }
  };

  useEffect(() => {
    fetchJudges();
  }, []);

  return (
    <Stack
      sx={{
        px: 4,
        py: 4,
        ...theme.darkBg,
      }}
      spacing={4}
    >
      {/* Dashboard Statistics */}
      <Box
        sx={{
          p: 2,
          backgroundColor: "rgba(0, 0, 0, 0.16)",
          borderRadius: "4px",
          ...theme.font.h1,
          ...theme.light,
          color: theme.orange,
        }}
      >
        <h1>Judging Dashboard</h1>
        <Stack
              sx={{
                height: "8rem",
                width: "8rem",
              }}
            >
              <Logo />
            </Stack>
        <Stack direction="row" justifyContent="space-between"
        sx={{
          ...theme.font.h4,
          color: "white",
          width: "50%",
          margin: "auto"
        }}>
          <Box>
            <strong>Total Judges:</strong> {statistics.totalJudges}
          </Box>
          <Box>
            <strong>Judges Completed:</strong> {statistics.completedJudges}
          </Box>
          <Box>
            <strong>Overall Progress:</strong> {statistics.totalProgress}%
          </Box>
        </Stack>
      </Box>

      {/* Judges List */}
      {judges.map((judge) => (
        <Box
          key={judge.email}
          sx={{
            mb: 2,
            p: 2,
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: "rgba(0, 0, 0, 0.16)",
            color: "white",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => setExpandedJudge(expandedJudge === judge.email ? null : judge.email)}
            sx={{
              cursor: "pointer",
              color: judge.assignedApplications.every((app) => app.judged) ? "green" : "red",
            }}
          >
            <strong>{judge.email}</strong>
            <Stack direction="row" alignItems="center">
              {judge.assignedApplications.every((app) => app.judged) ? (
                <CheckCircle sx={{ color: "green" }} />
              ) : (
                <ErrorOutline sx={{ color: "red" }} />
              )}
              <span>
                {judge.assignedApplications.filter((app) => app.judged).length}/
                {judge.assignedApplications.length} Applications
              </span>
              {expandedJudge === judge.email ? <ExpandLess /> : <ExpandMore />}
            </Stack>
          </Stack>

          {expandedJudge === judge.email && (
            <Box
              sx={{
                mt: 2,
                p: 2,
                borderRadius: "4px",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              }}
            >
              {judge.assignedApplications.map((app) => (
                <Box key={app.id}>
                  <Box
                    sx={{
                      p: 1,
                      mb: 1,
                      cursor: "pointer",
                      borderRadius: "4px",
                      border: "1px solid #ddd",
                      backgroundColor: app.judged ? "rgba(0, 255, 0, 0.1)" : "rgba(255, 0, 0, 0.1)",
                    }}
                    onClick={() => {
                      setSelectedApplication(app);
                      setSelectedJudge(judge);
                    }}
                  >
                    {app.startup_name}
                    {app.judged ? (
                      <CheckCircle sx={{ color: "green", ml: 1 }} />
                    ) : (
                      <Cancel sx={{ color: "red", ml: 1 }} />
                    )}
                  </Box>

                  {selectedApplication && selectedApplication.id === app.id && (
                    <Box
                      sx={{
                        mt: 1,
                        p: 2,
                        borderRadius: "4px",
                        backgroundColor: "rgba(0, 0, 0, 0.16)",
                      }}
                    >
                      <h4>Application Details</h4>
                      <p>
                        <strong>Startup Name:</strong> {app.startup_name}
                      </p>
                      <p>
                        <strong>Currently Assigned to:</strong> {judge.email}
                      </p>
                      <p>
                        <strong>Other Judges:</strong>{" "}
                        {judges
                          .filter((j) => j.assignedApplications.some((a) => a.id === app.id))
                          .map((j) => j.email)
                          .join(", ")}
                      </p>
                      <select
                        onChange={(e) => moveApplication(app, judge, e.target.value)}
                        defaultValue=""
                        style={{
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid #ddd",
                        }}
                      >
                        <option value="" disabled>
                          Select Judge
                        </option>
                        {judgesForDropdown.map((email) => (
                          <option key={email} value={email}>
                            {email}
                          </option>
                        ))}
                      </select>
                      <Button
                        onClick={() => {
                          setSelectedApplication(null);
                          setSelectedJudge(null);
                        }}
                        startIcon={<Close />}
                        sx={{ mt: 1 }}
                      >
                        Close
                      </Button>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      ))}
    </Stack>
  );
}

export default Assign;