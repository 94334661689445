import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/Home";
import Apply from "./pages/apply/Apply";
import Dashboard from "./pages/Dashboard";
import EditTeam from "./pages/EditTeam";
import Leaderboard from "./pages/Leaderboard";
import Sorting from "./pages/Sorting";
import Assign from "./pages/Assign";
import Judging from "./pages/Judging";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import NoPage from "./pages/NoPage";
import ProtectedRoute from "./ProtectedRoute";
import "./App.css";
import Layout from "./layout/Layout";
import { Navigate } from "react-router-dom";

import { logOut } from "./firebase.js";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import Partners from "./pages/Partners";
import NewUser from "./pages/NewUser";
import JudgingDashboard from "./pages/JudgingDashboard";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="home/*" element={<Layout />} />
        <Route path="apply" element={<Apply />} />
        <Route
          path="dashboard"
          element={
            <ProtectedRoute
              key={1}
              loggedinOnly={true}
              element={<Dashboard />}
              redirect={"/login"}
              judgeAccess={false}
            />
          }
        />
        <Route
          path="editteam"
          element={
            <ProtectedRoute
              key={1}
              loggedinOnly={true}
              element={<EditTeam />}
              redirect={"/login"}
              judgeAccess={false}
            />
          }
        />
        <Route
          path="leaderboard"
          element={
            <ProtectedRoute
              key={4}
              loggedinOnly={true}
              element={<Leaderboard />}
              redirect={"/login"}
              judgeAccess={false}
            />
          }
        />
        <Route
          path="login"
          element={
            <ProtectedRoute
              key={2}
              loggedinOnly={false}
              element={<Login />}
              redirect={"/dashboard"}
              judgeAccess={true}
            />
          }
        />
        <Route path="logout" element={<Logout />} />
        <Route
          path="sort"
          element={
            <ProtectedRoute
              key={3}
              loggedinOnly={true}
              element={<Sorting />}
              redirect={"/login"}
              judgeAccess={false}
            />
          }
        />
        <Route
          path="newuser"
          element={
            <ProtectedRoute
              key={7}
              loggedinOnly={true}
              element={<NewUser />}
              redirect={"/login"}
              judgeAccess={false}
            />
          }
        />
        <Route
          path="judging-dashboard"
          element={
            <ProtectedRoute
              key={7}
              loggedinOnly={true}
              element={<JudgingDashboard />}
              redirect={"/login"}
              judgeAccess={false}
            />
          }
        />
        <Route
          path="assign"
          element={
            <ProtectedRoute
              key={4}
              loggedinOnly={true}
              element={<Assign />}
              redirect={"/login"}
              judgeAccess={false}
            />
          }
        />
        <Route
          path="judge"
          element={
            <ProtectedRoute
              key={4}
              loggedinOnly={true}
              element={<Judging />}
              redirect={"/login"}
              judgeAccess={true}
            />
          }
        />

        <Route path="*" element={<Navigate to={"/home"} />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
