import React, { useState, useEffect } from "react";
import { db, auth, logOut, provider } from "../firebase.js";
import { Link, useNavigate } from "react-router-dom";

import {
  collection,
  doc,
  updateDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import styles from "./Dashboard.module.css";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import getClosestRegional from "./getClosestRegional.js";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import Logo from "../generic/Logo";
import BigButton from "../generic/BigButton";
import { CSVLink } from "react-csv";
import { Dropdown, DropdownButton } from "react-bootstrap";
import ApplicationKeys from "./ApplicationKeys.js";

function Dashboard() {
  const appsRef = collection(db, "apps");
  const theme = useTheme();
  const navigate = useNavigate();

  let [applications, setApplications] = useState([]);
  let [customApps, setCustomApps] = useState([]);
  let [sortBy, setSortBy] = useState({
    sortKey: "company_name",
    reversed: false,
  });

  // useEffect(() => {
  //   if (loading) return;
  //   if (!user) return navigate("/login");
  // }, [user, loading]);

  useEffect(() => {
    // getClosestRegional();
    // const q = query(appsRef, where("year", "==", yearFilter));
    const unsubscribe = onSnapshot(appsRef, (querySnapshot) => {
      let data = [];
      querySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      setApplications(data);
      setCustomApps(data);
    });
    return () => unsubscribe();
  }, []);

  const initialInputs = ApplicationKeys();

  const tableHeadings = Object.keys(initialInputs);

  const csv_headers = [
    { label: "Company Name", key: "company_name" },
    { label: "Student Member", key: "has_student" },
    { label: "Region", key: "region" },
    { label: "Closest Airport", key: "airport" },
    { label: "Closest Regional", key: "closest_regional" },
    { label: "Website", key: "url" },
    { label: "Industries", key: "categories" },
    { label: "Social Impact", key: "social_impact" },
    { label: "Unique Qualifications", key: "unique_team" },
    { label: "Team Emails", key: "team_linkedins" },
    { label: "Competition", key: "competitors" },
    { label: "Market Size", key: "market_size" },
    { label: "Traction", key: "traction" },
    { label: "Goals", key: "goals" },
    { label: "Video", key: "video" },
    { label: "Primary Contact", key: "primary_contact" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Member Universities", key: "univeristies" },
    { label: "Exposure", key: "exposure" },
    { label: "Year", key: "year" },
    { label: "Score", key: "score" },
  ];

  document.body.style.backgroundColor = "#1e1e30";

  const [show, setShow] = useState(null);

  const handleClose = () => setShow(null);
  const handleShow = (app) => {
    setShow(app);
  };

  const updateScore = (newScore) => {
    console.log("updating");
    updateDoc(doc(db, "apps", show.id), {
      score: newScore,
    });

    setShow(null);
  };

  const truncate = (str) => {
    if (typeof str === "string") {
      if (str.length > 50) {
        return str.substring(0, 50) + "...";
      }
    }
    return str;
  };

  const sortDict = (dict, keyName, reversed) => {
    let sorted = dict.sort((a, b) => {
      let type = typeof a[keyName];
      if (a === undefined && b === undefined) {
        return 0;
      }
      if (a === undefined) {
        return -1;
      }
      if (b === undefined) {
        return 1;
      }
      if (type === "string") {
        return a[keyName].localeCompare(b[keyName]);
      } else if (type === "number") {
        console.log("running?");
        return a[keyName] - b[keyName];
      }
    });
    if (reversed) {
      return sorted.reverse();
    }
    return sorted;
  };

  // if (loading) {
  //   return <></>;
  // }

  const findClosestRegional = () => {
    applications.forEach(async (application) => {
      if (application.closest_regional === "undetermined") {
        console.log(application);
        let closestRegional = await getClosestRegional(application.region);
        await updateDoc(doc(db, "apps", application.id), {
          closest_regional: closestRegional,
        });
      }
    });
  };

  const nukeAllScores = () => {
    applications.forEach(async (application) => {
      await updateDoc(doc(db, "apps", application.id), {
        score: 0,
      });
    });
  };

  function AppChunk(props) {
    const { text, location } = props;
    return (
      <Stack
        sx={{
          p: 2,
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {text + ":"}
        <Stack>
          {applications.filter((a) => a.closest_regional === location).length}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        ...theme.darkBg,
        minHeight: "100vh",
      }}
    >
      <Modal show={show} onHide={handleClose} scrollable={true}>
        {show !== -1 && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{show?.company_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {tableHeadings.map((key) => {
                return (
                  <div key={key}>
                    <p>{key}</p>
                    <p className="ps-3 mb-4">
                      <b>{show?.hasOwnProperty(key) ? show[key] : ""}</b>
                    </p>
                  </div>
                );
              })}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => updateScore(0)}>
                Set Unmarked
              </Button>
              <Button variant="success" onClick={() => updateScore(1)}>
                Set Green
              </Button>
              <Button variant="warning" onClick={() => updateScore(2)}>
                Set Yellow
              </Button>
              <Button variant="danger" onClick={() => updateScore(3)}>
                Set Red
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
      <Stack
        sx={{
          ...theme.font.h1,
          ...theme.light,
          fontFamily: "'Rubik'",
          flexShrink: 1,
          p: 4,
        }}
        direction="row"
        spacing={{
          md: 4,
          xs: 2,
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          spacing={{
            md: 4,
            xs: 2,
          }}
          sx={{
            minWidth: "50%",
          }}
        >
          <Stack
            direction="row"
            spacing={{
              md: 4,
              xs: 2,
            }}
          >
            <Stack
              sx={{
                height: "8rem",
              }}
            >
              <Logo />
            </Stack>
            <Stack justifyContent="center">Applications Dashboard</Stack>
          </Stack>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <BigButton
                text={"Sorting"}
                onClick={() => navigate("/sort")}
                sx={{
                  ...theme.font.h4,
                }}
              />
              <BigButton
                text={"Judging"}
                onClick={() => navigate("/judge")}
                sx={{
                  ...theme.font.h4,
                }}
              />
              <BigButton
                text={"Leaderboard"}
                onClick={() => navigate("/leaderboard")}
                sx={{
                  ...theme.font.h4,
                }}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <BigButton
                text={"Assign"}
                onClick={() => navigate("/assign")}
                sx={{
                  ...theme.font.h4,
                }}
              />
              <BigButton
                text={"New User"}
                onClick={() => navigate("/newuser")}
                sx={{
                  ...theme.font.h4,
                }}
              />
              <BigButton
                text={"Judging Dashboard"}
                onClick={() => navigate("/judging-dashboard")}
                sx={{
                  ...theme.font.h4,
                }}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <BigButton
                text={"Tim's Hot Reload Button"}
                onClick={findClosestRegional}
                sx={{
                  ...theme.font.h4,
                }}
              />
              {/* <BigButton
                text={"Jackie's Score Nuker"}
                onClick={nukeAllScores}
                sx={{
                  ...theme.font.h4,
                }}
              /> */}
              <BigButton
                sx={{
                  ...theme.font.h4,
                }}
              >
                <CSVLink
                  data={applications}
                  headers={csv_headers}
                  filename={"TL_Applications.csv"}
                  style={{
                    color: "white",
                  }}
                >
                  Download CSV
                </CSVLink>
              </BigButton>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            height: "100%",
            width: "100%",
            py: 2,
          }}
          justifyContent="flex-end"
          direction="row"
        >
          <Grid
            container
            sx={{
              height: "fit-content",
              width: "max(25%, 24rem)",
            }}
            direction={"row"}
            spacing={1}
          >
            <Grid
              item
              xs={12}
              sx={{
                ...theme.font.h1,
              }}
            >
              <Stack
                sx={{
                  p: 2,
                  height: "100%",
                  width: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderTop: "4px solid white",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                Total:
                <Stack>{applications.length}</Stack>
              </Stack>
            </Grid>
            {[0, 1, 2, 3].map((v) => {
              let color = "white";

              if (v === 3) color = "#ff6961";
              if (v === 2) color = "#dded66";
              if (v === 1) color = "#77dd77";

              return (
                <Grid item xs={3}>
                  <Stack
                    sx={{
                      p: 2,
                      height: "100%",
                      width: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      ...theme.font.h3,
                      color: color,
                    }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Stack>
                      {applications.filter((a) => a.score === v).length}
                    </Stack>
                    <Stack
                      sx={{
                        ...theme.sortButton,
                      }}
                    >
                      {(
                        (applications.filter((a) => a.score === v).length /
                          applications.length) *
                        100
                      ).toFixed(1)}
                      %
                    </Stack>
                  </Stack>
                </Grid>
              );
            })}
            <Grid
              item
              xs={6}
              sx={{
                ...theme.font.h5,
              }}
            >
              <AppChunk text="Singapore" location="Singapore" />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                ...theme.font.h5,
              }}
            >
              <AppChunk text="Paris" location="Paris, France" />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                ...theme.font.h5,
              }}
            >
              <AppChunk text="New York" location="New York, NY" />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                ...theme.font.h5,
              }}
            >
              <AppChunk text="Berkeley" location="Berkeley, CA" />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                ...theme.font.h5,
              }}
            >
              <AppChunk text="Houston" location="Houston, TX" />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                ...theme.font.h5,
              }}
            >
              <AppChunk text="TBD" location="undetermined" />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <Stack></Stack>
      <Stack
        sx={{
          color: "white",
          px: 4,
          pb: 4,
        }}
        spacing={2}
      >
        <Stack>
          <CustomFilter
            applications={applications}
            onClick={(apps) => {
              setCustomApps(apps);
            }}
          />
        </Stack>
        <Box
          component="table"
          sx={{
            maxHeight: "80vh",
            position: "relative",
            borderCollapse: "separate",
            borderSpacing: "0",
            borderTop: "4px solid white",
            borderRight: "1px solid white",
            borderLeft: "1px solid white",
            borderBottom: "1px solid white",
          }}
        >
          <Box
            component="thead"
            sx={{
              ...theme.darkBg,
              position: "sticky",
              top: 0,
            }}
          >
            <Box
              component="tr"
              sx={{
                ...theme.font.h5,
                fontFamily: "'Rubik'",
              }}
            >
              {tableHeadings.map((heading, index) => (
                <Box
                  component="th"
                  key={index}
                  onClick={(e) => {
                    // console.log(e.target.innerText);
                    setSortBy((prev) => {
                      if (prev.sortKey === heading) {
                        return { ...prev, reversed: !prev.reversed };
                      } else {
                        return { sortKey: heading, reversed: false };
                      }
                    });
                  }}
                  sx={{
                    p: 2,
                    borderBottom: "1px solid white",
                    borderRight: "1px solid gray",
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                >
                  {heading
                    .split("_")
                    .map((v) => v.charAt(0).toUpperCase() + v.substring(1))
                    .join(" ")}
                </Box>
              ))}
              <Box
                component="th"
                sx={{
                  p: 2,
                  borderBottom: "1px solid white",
                  borderRight: "1px solid gray",
                }}
              >
                Last Access (for Tim)
              </Box>
              <Box
                component="th"
                sx={{
                  p: 2,
                  borderBottom: "1px solid white",
                  borderRight: "1px solid gray",
                }}
              >
                id
              </Box>
            </Box>
          </Box>
          <Box component="tbody">
            {sortDict(customApps, sortBy.sortKey, sortBy.reversed).map(
              (application, index) => {
                let cols = [];
                let color = "";
                tableHeadings.forEach((key) => {
                  cols.push(
                    <Box
                      component="td"
                      sx={{
                        py: 1,
                        px: 2,
                        borderRight: "1px solid gray",
                      }}
                      key={key}
                    >
                      {truncate(application[key])}
                    </Box>
                  );
                });

                switch (application["score"]) {
                  case 1:
                    color = "#77dd77";
                    break;
                  case 2:
                    color = "#dded66";
                    break;
                  case 3:
                    color = "#ff6961";
                    break;
                  default:
                    break;
                }

                return (
                  <Box
                    component="tr"
                    onClick={() => handleShow(application)}
                    key={index}
                    id={index}
                    sx={{
                      backgroundColor: color ?? theme.dark,
                      color: color ? "black" : "white",
                    }}
                  >
                    {cols}
                    <td>{JSON.stringify(application.last_access)}</td>
                    <td>{JSON.stringify(application.id)}</td>
                  </Box>
                );
              }
            )}
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
}

const keys = ApplicationKeys();

const CustomFilter = (props) => {
  let [customKey, setCustomKey] = useState("startup_name");
  let [customVal, setCustomVal] = useState("");
  let [filterCount, setFilterCount] = useState(0);

  const theme = useTheme();

  const trySubmit = () => {
    if (!customVal) {
      props.onClick(props.applications);
      return;
    }

    let result = [];
    for (let app of props.applications) {
      if (app[customKey] && validate(app[customKey], customVal)) {
        result.push(app);
      }
    }
    props.onClick(result);
  };

  const validate = (thing, val) => {
    if (typeof thing === "string") {
      return thing.toLowerCase().includes(val.toLowerCase());
    }
    if (typeof thing === "number") {
      if (val === "green") return thing === 1;
      if (val === "yellow") return thing === 2;
      if (val === "red") return thing === 3;
      return thing === val;
    }
    if (Array.isArray(thing)) {
      return thing.includes(val);
    }
    return false;
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <Stack spacing={1}>
          <DropdownButton
            variant="dark"
            title={customKey}
            sx={{
              width: "100%",
            }}
          >
            {Object.keys(keys).map((v) => {
              return (
                <Dropdown.Item
                  onClick={() => {
                    setCustomKey(v);
                  }}
                >
                  {v}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </Stack>
        <Stack
          sx={{
            width: "100%",
          }}
          spacing={1}
        >
          <Box
            component="input"
            sx={{
              width: "100%",
              flex: 1,
              ...theme.font.h5,
            }}
            placeholder="Search..."
            type="text"
            onChange={(e) => setCustomVal(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") trySubmit();
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Dashboard;
