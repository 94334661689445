import React, { useState, useEffect } from "react";
import { db } from "../firebase.js";

import {
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import styles from "./Dashboard.module.css";
import { CSVLink } from "react-csv";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Stack, Box, useTheme } from "@mui/material";
import Logo from "../generic/Logo";
import Tooltip from "@mui/material/Tooltip";
import { Warning } from "@mui/icons-material";

function Leaderboard() {
  const appsRef = collection(db, "apps");
  const authRef = collection(db, "user-emails");
  const ratingsRef = collection(db, "ratings");

  let [judges, setJudges] = useState([]);
  let [judgeScores, setJudgeScores] = useState({});
  let [companies, setCompanies] = useState({});
  let [ratings, setRatings] = useState([]);
  let [sortBy, setSortBy] = useState({
    sortKey: "startup_name",
    reversed: false,
  });
  let [topN, setTopN] = useState(10);
  let [sortOrder, setSortOrder] = useState("desc"); // New state for sort order

  const getCompanies = async () => {
    let snapshot = await getDocs(
      // query(appsRef, where("last_access", "==", null))
      query(appsRef, where("score", "==", 1))
    );

    let temp = {};
    snapshot.docs.forEach((doc) => {
      temp[doc.id] = {
        ...doc.data(),
        raw_total_score: 0,
        total_score: 0,
        score_count: 0,
        avg_raw_score: 0,
        avg_score: 0,
        judge_comments: [],
      };
    });
    setCompanies(temp);
    getRatings();
  };

  const getRatings = async () => {
    let snapshot = await getDocs(ratingsRef);

    setRatings(
      snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
        };
      })
    );
  };
  console.log(ratings);

  const getJudges = async () => {
    let snapshot = await getDocs(
      // query(appsRef, where("last_access", "==", null))
      query(authRef, where("judge", "==", true))
    );

    setJudges(
      snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      })
    );
  };

  useEffect(() => {
    // getCompanies();
    getJudges();
    getCompanies();
  }, []);

  useEffect(() => {
    let scores = {};
    ratings.forEach((rating) => {
      if (!(rating.judge in scores)) {
        scores[rating.judge] = { data: [], mean: 0, sd: 0 };
      }
      scores[rating.judge].data.push(rating.rawscore);
    });
    for (const judge in scores) {
      let data = scores[judge].data;
      let mean = data.reduce((a, b) => a + b) / data.length;
      scores[judge].mean = mean;
      scores[judge].sd = Math.sqrt(
        data.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) /
          data.length
      );
    }
    setCompanies((currentCompanies) => {
      console.log("COMPANIES");
      console.log(currentCompanies);
      ratings.forEach((rating) => {
        if (rating.application in currentCompanies) {
          let z = 0;
          if (scores[rating.judge].sd != 0) {
            z =
              (rating.rawscore - scores[rating.judge].mean) /
              scores[rating.judge].sd;
          }
          currentCompanies[rating.application].total_score += z;
          currentCompanies[rating.application].raw_total_score +=
            rating.rawscore;
          currentCompanies[rating.application].score_count++;
          currentCompanies[rating.application].avg_raw_score =
            currentCompanies[rating.application].raw_total_score /
            currentCompanies[rating.application].score_count;
          currentCompanies[rating.application].avg_score =
            currentCompanies[rating.application].total_score /
            currentCompanies[rating.application].score_count;

          // comments
          if (rating.comments != "") {
            currentCompanies[rating.application].judge_comments.push(
              rating.comments
            );
          }
        }
      });
      return { ...currentCompanies };
    });
    console.log("this thing ran");
  }, [ratings]);

  const sortDict = (dict, keyName, reversed) => {
    let sorted = dict.sort((a, b) => {
      let type = typeof a[keyName];
      if (a === undefined && b === undefined) {
        return 0;
      }
      if (a === undefined) {
        return -1;
      }
      if (b === undefined) {
        return 1;
      }
      if (type === "string") {
        return a[keyName].localeCompare(b[keyName]);
      } else if (type === "number") {
        return a[keyName] - b[keyName];
      }
    });
    if (reversed) {
      return sorted.reverse();
    }
    return sorted;
  };

  console.log("JUDGES");
  console.log(judges);

  const headers = ["email", "judged", "toJudge", "id"];
  const companyHeaders = [
    "startup_name",
    "avg_score",
    "avg_raw_score",
    "score_count",
  ];
  const regionals = [
    "Paris, France",
    "Singapore",
    "Houston, TX",
    "New York, NY",
    "Berkeley, CA",
  ];

  const csv_headers = [
    { label: "Startup Name", key: "startup_name" },
    { label: "Region", key: "region" },
    { label: "Closest Airport", key: "airport" },
    { label: "Closest Regional", key: "closest_regional" },
    { label: "Primary Contact", key: "applier_name" },
    { label: "Email", key: "applier_email" },
    { label: "Phone", key: "applier_phone" },
    { label: "Judge Comments", key: "judge_comments" },
  ];

  const handleTopNChange = (e) => {
    setTopN(e.target.value);
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  };

  const theme = useTheme();

  return (
    <Stack
      sx={{
        px: 4,
        py: 4,
        ...theme.darkBg,
      }}
      spacing={4}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "rgba(0, 0, 0, 0.16)",
          borderRadius: "4px",
          ...theme.font.h1,
          ...theme.light,
          color: theme.orange,
        }}
      >
        <h1>Leaderboard</h1>
        <Stack
          sx={{
            height: "8rem",
            width: "8rem",
          }}
        >
          <Logo />
        </Stack>
        <Box sx={{ ...theme.font.h4, color: "white", paddingTop:"10px" }}>
          <p>
            This leaderboard displays the average scores of startups based on judges' ratings. 
            The average score is calculated by normalizing the raw scores given by judges to account for any bias.
            The raw score is the direct score given by the judge, while the average score is the normalized score.
          </p>
        </Box>
        <Stack direction="row" spacing={2} alignItems="center">
          <CSVLink
            data={Object.values(companies)}
            headers={csv_headers}
            filename={"TL_Leaderboard.csv"}
            style={{ 
              fontSize: "1.25rem",             
              padding: "8px 16px",
              borderRadius: "4px",
              backgroundColor: theme.orange,
              color: "white",
              textDecoration: "none",
            }}
          >
            Download CSV (All Regions)
          </CSVLink>
          <Box sx={{ ...theme.font.h4, color: "white" }}>
            <label>
              Show top N:
              <input
                type="number"
                value={topN}
                onChange={handleTopNChange}
                min="1"
                style={{
                  fontSize: "1.25rem",             
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ddd",
                  marginLeft: "8px",
                }}
              />
            </label>
          </Box>
          <Box sx={{ ...theme.font.h4, color: "white" }}>
            <label>
              Sort Order:
              <select
                value={sortOrder}
                onChange={handleSortOrderChange}
                style={{
                  fontSize: "1.25rem",
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ddd",
                  marginLeft: "8px",
                }}
              >
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </label>
          </Box>
        </Stack>
      </Box>

      <Tabs defaultActiveKey={regionals[0]} id="regional-tabs">
        {regionals.map((regional) => (
          <Tab eventKey={regional} title={regional} key={regional}>
            <Box
              sx={{
                p: 2,
                backgroundColor: "rgba(0, 0, 0, 0.16)",
                borderRadius: "4px",
                color: "white",
              }}
            >
              <h2>{regional}</h2>
              <table
                className="table table-striped table-hover"
                style={{ maxHeight: "80vh", display: "table" }}
              >
                <thead>
                  <tr>
                    <th>Startup Name</th>
                    <th>Average Score</th>
                    <th>Average Raw Score</th>
                    <th>Location</th>
                    <th>Judging Status</th>
                  </tr>
                </thead>
                <tbody>
                  {sortDict(
                    Object.values(companies).filter(
                      (company) => company.closest_regional == regional
                    ),
                    "avg_score",
                    sortOrder === "desc"
                  )
                    .slice(0, topN)
                    .map((company, index) => {
                      const isIncomplete = company.score_count < 2;
                      return (
                        <tr key={index}>
                          <td>{company.startup_name}</td>
                          <td>{company.avg_score.toFixed(2)}</td>
                          <td>{company.avg_raw_score.toFixed(2)}</td>
                          <td>{company.region}</td>
                          <td>
                            {company.score_count}
                            {isIncomplete && (
                              <Tooltip title="This application has not been fully judged yet.">
                                <Warning sx={{ color: "yellow", ml: 1 }} />
                              </Tooltip>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Box>
          </Tab>
        ))}
      </Tabs>
    </Stack>
  );
}

export default Leaderboard;
