import React from "react";
import { Box, Grid, Stack, useTheme } from "@mui/material";
import "../scss/core.scss";
import { Email, Instagram, Facebook, LinkedIn } from "@mui/icons-material";
import odus from "../img/odus.png";
import eclub from "../img/eclub.png";

export default function (props) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        ...theme.lightBg,
        py: 8,
      }}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        sx={{
          ...theme.container,
        }}
        justifyContent="center"
        alignItems="center"
        direction={{
          md: "row",
          xs: "column",
        }}
        spacing={2}
      >
        <Grid item xs={12} md={4}>
          <Stack
            sx={{
              ...theme.smallTitle,
              display: {
                md: "flex",
                xs: "none",
              },
            }}
          >
            Contact Us
          </Stack>
          <Grid
            container
            columnSpacing={2}
            justifyContent={{
              xs: "center",
              md: "flex-start",
            }}
          >
            <Grid
              item
              xs={1.25}
              md={2}
              sx={{
                aspectRatio: "1 / 1",
              }}
            >
              <Email
                sx={{
                  height: "100%",
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open("mailto:rg6134@princeton.edu", "_self")
                }
              />
            </Grid>
            <Grid item xs={1.25} md={2}>
              <Instagram
                sx={{
                  height: "100%",
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open("https://www.instagram.com/tigerlaunch", "_blank")
                }
              />
            </Grid>
            <Grid item xs={1.25} md={2}>
              <Facebook
                sx={{
                  height: "100%",
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open("https://www.facebook.com/tigerlaunch", "_blank")
                }
              />
            </Grid>
            <Grid item xs={1.25} md={2}>
              <LinkedIn
                sx={{
                  height: "100%",
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/tigerlaunch",
                    "_blank"
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Stack
            direction="row-reverse"
            justifyContent={{
              xs: "center",
              md: "flex-start",
            }}
            sx={{
              width: "100%",
            }}
          >
            <Box
              component={"img"}
              sx={{
                width: {
                  xs: "40%",
                  md: "35%",
                },
                height: "auto",
                objectFit: "contain",
              }}
              src={eclub}
            />
            <Box
              component={"img"}
              sx={{
                width: {
                  xs: "40%",
                  md: "35%",
                },
                height: "auto",
                objectFit: "contain",
              }}
              src={odus}
            />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
