import { Grid, Stack, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { LayoutContext } from "../layout/Layout";

import allTeamsPhoto from "../img/team/group/all_team.JPG";
import partnershipsTeamPhoto from "../img/team/group/partnerships.jpg";
import operationsTeamPhoto from "../img/team/group/operations.jpg";
import brandingTeamPhoto from "../img/team/group/branding.jpg";

const importHeadshot = (name) => {
  try {
    return require(`../img/team/individual/${name.toLowerCase()}.JPG`);
  } catch (err) {
    console.error(`Error loading image for ${name}:`, err);
    return null;
  }
};

const board = [
  ["haruka", "Haruka Nabeshima", "Co-Director"],
  ["jack", "Jack Prewitt", "Co-Director"],
  ["athan", "Athan Zhang", "Treasurer"],
];

const partnerships = [
  ["alexei", "Alexei Korolev", "Partnerships Lead"],
  ["terese", "Terese Palomino", "Partnerships"],
  ["noah", "Noah Avis", "Partnerships"],
  ["jackh", "Jack Hasker", "Partnerships"],
  ["evelyn", "Ruotong (Evelyn) Bai", "Partnerships"],
  ["nico", "Nico Vitale", "Partnerships"],
];

const operations = [
  ["jeffrey", "Jeffrey Xu", "Operations Lead"],
  ["eliana", "Eliana Bane", "Operations Lead"],
  ["nathan", "Nathan Nguyen", "Operations"],
  ["sindhuja", "Sindhuja Kanamarlapudi", "Operations"],
  ["sawooly", "Sawooly Li", "Operations"],
  ["shayla", "Shayla Zheng", "Operations"],
  ["ziya", "Ziya Momin", "Operations"],
  ["andrew", "Andrew Zhou", "Operations"],
];

const branding = [
  ["natalie", "Natalie Laszewski", "Branding Lead"],
  ["winsice", "Winsice Ng", "Branding"],
  ["jason", "Jason Persaud", "Branding"],
  ["katie", "Katie Lee", "Branding"],
  // ["liepa", "Liepa Vysniauskaite", "Branding"],
  ["yuchen", "Yuchen Shi", "Branding"],
];

export default function Team(props) {
  const theme = useTheme();
  const layout = useContext(LayoutContext);

  return (
    <Stack>
      <Stack
        sx={{
          ...theme.darkBg,
          overflow: "hidden",
        }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Stack
          sx={{
            ...theme.container,
          }}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Stack
            sx={{
              position: "relative",
              marginTop: "12rem",
              paddingBottom: "8rem",
              width: "100%",
            }}
            alignItems="center"
            spacing={4}
          >
            <Stack
              sx={{
                ...theme.font.h0,
                color: "white",
                py: 2,
                borderBottom: "1px solid white",
                width: "100%",
                textAlign: "center",
              }}
            >
              Meet the Team
            </Stack>
            <Grid container rowSpacing={2} justifyContent={"center"}>
              <Stack
                sx={{
                  color: "white",
                  width: "100%",
                  p: 4,
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                }}
                spacing={2}
              >
                <Grid item xs={12} md={12}>
                  <img
                    src={allTeamsPhoto}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderTop: `4px solid ${theme.orange}`,
                    }}
                  />
                </Grid>
              </Stack>
              {board.map((person) => (
                <Grid item xs={12} md={4} key={person[0]}>
                  <NameCard data={person} />
                </Grid>
              ))}
              <Stack
                sx={{
                  color: "white",
                  width: "100%",
                  p: 4,
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                }}
                spacing={2}
              >
                <Stack
                  sx={{
                    ...theme.font.h0,
                    color: "white",
                    py: 2,
                    borderBottom: "1px solid white",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Partnerships
                </Stack>
                <Grid item xs={12} md={12}>
                  <img
                    src={partnershipsTeamPhoto}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderTop: `4px solid ${theme.orange}`,
                    }}
                  />
                </Grid>
              </Stack>
              {partnerships.map((person) => (
                <Grid item xs={12} md={4} key={person[0]}>
                  <NameCard data={person} />
                </Grid>
              ))}
              <Stack
                sx={{
                  color: "white",
                  width: "100%",
                  p: 4,
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                }}
                spacing={2}
              >
                <Stack
                  sx={{
                    ...theme.font.h0,
                    color: "white",
                    py: 2,
                    borderBottom: "1px solid white",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Operations
                </Stack>
                <Grid item xs={12} md={12}>
                  <img
                    src={operationsTeamPhoto}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderTop: `4px solid ${theme.orange}`,
                    }}
                  />
                </Grid>
              </Stack>
              {operations.map((person) => (
                <Grid item xs={12} md={4} key={person[0]}>
                  <NameCard data={person} />
                </Grid>
              ))}
              <Stack
                sx={{
                  color: "white",
                  width: "100%",
                  p: 4,
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                }}
                spacing={2}
              >
                <Stack
                  sx={{
                    ...theme.font.h0,
                    color: "white",
                    py: 2,
                    borderBottom: "1px solid white",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Branding
                </Stack>
                <Grid item xs={12} md={12}>
                  <img
                    src={brandingTeamPhoto}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderTop: `4px solid ${theme.orange}`,
                    }}
                  />
                </Grid>
              </Stack>
              {branding.map((person) => (
                <Grid item xs={12} md={4} key={person[0]}>
                  <NameCard data={person} />
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export function NameCard(props) {
  const { data } = props;
  const theme = useTheme();

  return (
    <Stack
      sx={{
        color: "white",
        width: "100%",
        p: 4,
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      }}
      spacing={2}
    >
      <img
        src={importHeadshot(data[0])}
        style={{
          width: "100%",
          height: "auto",
          aspectRatio: "2/1.8",
          objectFit: "cover",
          borderTop: `4px solid ${theme.orange}`,
        }}
      />
      <Stack direction="column" spacing={1} justifyContent="center">
        <Stack
          sx={{
            ...theme.font.h3,
          }}
        >
          {data[1]}
        </Stack>
        <Stack
          sx={{
            ...theme.font.h5,
            color: theme.lightOrange,
          }}
        >
          {data[2]}
        </Stack>
      </Stack>
    </Stack>
  );
}
