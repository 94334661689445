import styles from "./apply/Apply.module.css";

import autosize from "autosize";
import { useEffect } from "react";
import { Stack, useTheme } from "@mui/material";
import React from "react";

const LongAnswer = (props) => {
  useEffect(() => {
    // forceUpdate();
    autosize(document.querySelectorAll("textarea"));
  }, []);

  const theme = useTheme();

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Stack sx={{ ...theme.question }}>{props.question}</Stack>
        <Stack sx={{ ...theme.subQuestion }}>{props.subquestion}</Stack>
      </Stack>
      <textarea
        rows={1}
        className="textarea"
        placeholder="Type your answer here"
        value={props.value}
        onChange={props.onChange}
      ></textarea>
    </Stack>
  );
};

export default LongAnswer;
